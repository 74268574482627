<template>
    <div style="height: 280px;">
        <div class="text-center" style="padding-top: 10px;">
            <div style="font-size: 16px;"><strong>{{title}}</strong><br /><span style="font-size: 14px;">{{data.sellers_with_sales}} de {{data.total_sellers}}</span></div>
        </div>        
        <div style="width: 100%; height: 260px;" ref="gaugeChart"></div>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import ws from '@/services/graphs';

import ws from '../../../services/dashboards';
import store from '../../../store'

export default {
    name: 'SellersWithSales',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default: 'Promedio'
        },
        challenge: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    components : {
	},
    data: function () {
		return {
            root: "",
            data: {}
        }
    },
    computed: {
        
    },
    mounted: async function(){      

    },
    methods: {
        async loadGraph(value){
            if (this.root) {
                this.root.dispose();
            }
            let data_response =[
                {
                    title: "",
                    color: "#ee1f25",
                     lowScore: 0,
                    highScore: 15
                }, {
                    title: "",
                    color: "#f04922",
                    lowScore: 15,
                    highScore: 30
                }, {
                    title: "",
                    color: "#fdae19",
                    lowScore: 30,
                    highScore: 45
                }, {
                    title: "",
                    color: "#f3eb0c",
                    lowScore: 45,
                    highScore: 60
                }, {
                    title: "",
                    color: "#b0d136",
                    lowScore: 60,
                    highScore: 75
                }, {
                    title: "",
                    color: "#54b947",
                    lowScore: 75,
                    highScore: 90
                }, {
                    title: "",
                    color: "#0f9747",
                    lowScore: 90,
                    highScore: 100
                }
            ];

            this.root = am5.Root.new(this.$refs.gaugeChart);        
            this.root.setThemes([am5themes_Animated.new(this.root)]);

            // Se inicializa el contenedor (al parecer puede contener varias graficas este mismo contenedor NO PROBADO)
            let chart = this.root.container.children.push(
                // Se inicializa el tipo de grafica
                am5radar.RadarChart.new(this.root, {
                    panX: false,
                    panY: false,
                    startAngle: 160,
                    endAngle: 380
                })
            );
            chart.getNumberFormatter().set("numberFormat", "#'%'");

            let axisRenderer = am5radar.AxisRendererCircular.new(this.root, {
                    innerRadius: -30
                });

            axisRenderer.grid.template.setAll({
                stroke: this.root.interfaceColors.get("background"),
                visible: false,
                strokeOpacity: 0.8
            });    

            let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
                    maxDeviation: 0,
                    min: 0,
                    max: 100,
                    strictMinMax: true,
                    renderer: axisRenderer
                }));

            let axisDataItem = xAxis.makeDataItem({});

            let clockHand = am5radar.ClockHand.new(this.root, {
                pinRadius: am5.percent(20),
                radius: am5.percent(100),
                bottomWidth: 40
            });

            let bullet = axisDataItem.set("bullet", am5xy.AxisBullet.new(this.root, {
                sprite: clockHand
            }));

            xAxis.createAxisRange(axisDataItem);

            let label = chart.radarContainer.children.push(am5.Label.new(this.root, {
                fill: am5.color(0xffffff),
                centerX: am5.percent(50),
                textAlign: "center",
                centerY: am5.percent(50),
                fontSize: "1em"
            }));

            axisDataItem.set("value", Number(value));

            bullet.get("sprite").on("rotation", function () {
                let value = axisDataItem.get("value");
                let fill = am5.color(0x000000);
                xAxis.axisRanges.each(function (axisRange) {
                    if (value >= axisRange.get("value") && value <= axisRange.get("endValue")) {
                    fill = axisRange.get("axisFill").get("fill");
                    }
                })

                label.set("text", Math.round(value).toString()+"%");

                clockHand.pin.animate({ key: "fill", to: fill, duration: 500, easing: am5.ease.out(am5.ease.cubic) })
                clockHand.hand.animate({ key: "fill", to: fill, duration: 500, easing: am5.ease.out(am5.ease.cubic) })
            });

            chart.bulletsContainer.set("mask", undefined);

            let helper = this.root;
            am5.array.each(data_response, function (data) {
                let axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}));

                axisRange.setAll({
                    value: data.lowScore,
                    endValue: data.highScore
                });

                axisRange.get("axisFill").setAll({
                    visible: true,
                    fill: am5.color(data.color),
                    fillOpacity: 0.8
                });

                axisRange.get("label").setAll({
                    text: data.title,
                    inside: true,
                    radius: 15,
                    fontSize: "0.9em",
                    fill: helper.interfaceColors.get("background")
                });
            });

            chart.appear(1000, 100);

        },
        async reloadGraph(challenge){
            this.loading();

            let response = await ws.getChallengeSellersWithSales(challenge.id);

            if(response.type == "success"){
                this.data = response.data;

                this.loadGraph(this.data.rate);
            }

            this.loaded();
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
  	beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
    }
}

</script>
