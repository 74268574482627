<template>
    <div>
        <div class="text-center">
            <div style="font-size: 16px;"><strong>{{title}}</strong></div>
        </div>        
        <div style="width: 100%; height: 800px;" ref="stackedBarChart">                            
        </div>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5index from "@amcharts/amcharts5/index";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import ws from '@/services/graphs';

import ws from '../../../services/dashboards';
import store from '../../../store'

export default {
    name: 'StackedBarGraph',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default() {
                return "Miembros en Verde"
            }
        }           
    },
    components : {
	},
    data: function () {
		return {
            items: {},
            root: "",
            chart: "",
            xAxis:"",
            yAxis:"",
            data_response:"",
            legend:"",
        }
    },
    computed: {
        
    },
    methods: {
        makeSeries(name, fieldName, root) {
            let series = this.chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                stacked: true,
                xAxis: this.xAxis,
                yAxis: this.yAxis,
                baseAxis: this.yAxis,
                valueXField: fieldName,
                categoryYField: "number"
            }));

            series.columns.template.setAll({
                tooltipText: "{name}, {categoryY}: {valueX}",
                tooltipY: am5.percent(90)
            });
            series.data.setAll(this.data_response);
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        text: "{valueX}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: am5.p50,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            this.legend.data.push(series);
        },
        loadGraph: async function(data_response){
            if (this.root) {
                this.root.dispose();
            }
            this.root = am5.Root.new(this.$refs.stackedBarChart);        
            let myTheme = am5.Theme.new(this.root);

            myTheme.rule("Grid", ["base"]).setAll({
                strokeOpacity: 0.1
            });

            this.root.setThemes([
                am5themes_Animated.new(this.root),
                myTheme
            ]);

            this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
                panX: false,
                panY: false,
                wheelX: false,
                wheelY: false,
                paddingLeft: 0,
                layout: this.root.verticalLayout
            }));

            // this.chart.chartContainer.wheelable = false;

            // this.chart.set("scrollbarY", am5.Scrollbar.new(this.root, {
            //     orientation: "horizontal"
            // }));

            this.data_response = data_response;

            let yRenderer = am5xy.AxisRendererY.new(this.root, {
                minGridDistance: 1,
            });
            this.yAxis = this.chart.yAxes.push(am5xy.CategoryAxis.new(this.root, {
                categoryField: "number",
                renderer: yRenderer,
                tooltip: am5.Tooltip.new(this.root, {})
            }));

            yRenderer.grid.template.setAll({
                location: 1,
            })

            this.yAxis.data.setAll(this.data_response);

            this.xAxis = this.chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
                min: 0,
                maxPrecision: 0,
                renderer: am5xy.AxisRendererX.new(this.root, {
                    minGridDistance: 40,
                    strokeOpacity: 0.1
                })
            }));

            // Configurar las etiquetas explícitamente
            this.yAxis.get("renderer").labels.template.setAll({
                oversizedBehavior: "wrap", // Permitir que las etiquetas largas se ajusten
                maxWidth: 120, // Ajustar ancho máximo
                fontSize: 10, // Reducir tamaño de fuente si es necesario
                rotation: 0, // Girar etiquetas para evitar solapamiento (opcional)
            });

            this.legend = this.chart.children.push(am5.Legend.new(this.root, {
                centerX: am5.p50,
                x: am5.p50
            }));

            this.makeSeries("Alcanzado", "income", this.root);
            this.makeSeries("Faltante", "expense", this.root);

            this.chart.appear(1000, 100);
        },
        async reloadGraph(challenge, type){
            this.loading();

            let response = await ws.getChallengeParticipation(challenge.id, type);

            if(response.type == "success"){
                this.data_response = response.data;

                this.loadGraph(this.data_response);
            }

            this.loaded();
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
  	mounted: async function(){    
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
  }
}

</script>
