<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <span style="font-size: 24px;">KPI's de Ventas</span>
          <div class="card-header-actions" style="width: 600px; margin-right: 18px;">
            <CRow>     
              <CCol md="8" style="text-align: right; padding-right: 0px;">                
              </CCol>                      
              <CCol md="4" style="text-align: right; padding-right: 0px;">
                <CSelect
                  :value.sync="month" 
                  :options="monthOptions"
                  @change="reloadGraphs"
                />
              </CCol>       
            </CRow>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="12">
              <CRow>                                           
                <CCol md="4">
                  <CCard>
                    <SellersWithSales ref="sellerWithSales" title="Vendedores con Ventas" :month="month"></SellersWithSales>
                  </CCard>      
                </CCol>     
                <CCol md="4">
                  <CWidgetSimple style="height: 280px;">
                    <CRow>  
                      <CCol md="12"> 
                        <CustomersReached ref="customersReached" title="Clientes Alcanzados:" :month="month"></CustomersReached>
                      </CCol> 
                    </CRow>
                  </CWidgetSimple>
                </CCol>     
                <CCol md="4">
                  <MonthSales ref="monthSales" title="Ventas del Mes:" :month="month"></MonthSales>
                </CCol>     
              </CRow>              
          </CCol>                   
        </CRow>
        <CRow>             
          <CCol md="6">
            <CedisPerformance ref="cedisPerformance" title="Desempeño por CEDIS" :month="month"></CedisPerformance>
          </CCol>                       
          <CCol md="6">
            <MonthPerformance ref="monthPerformance" title="Venta por Mes" :month="month"></MonthPerformance>
          </CCol>  
        </CRow>
        <CRow>             
          <CCol md="12"><br/><br/><br/></CCol>  
        </CRow>
        <CRow>          
          <CCol md="12">
            <ProjectionTable ref="projectionTable" title="Venta por Mes" :month="month"></ProjectionTable>
          </CCol>                       
        </CRow>
        <CRow>             
          <CCol md="12"><br/><br/><br/></CCol>  
        </CRow>
        <CRow>          
          <CCol md="12" class="text-center">
            <h3>Participación en Distribución Clientes</h3>
            <br />
          </CCol>      
          <CCol md="10" style="text-align: right; padding-right: 0px;">                
            </CCol>                      
            <CCol md="2" style="text-align: right; padding-right: 12px;">
              <CSelect
                  :value.sync="cedis" 
                  :options="cedisOptions"
                  @change="reloadCustomerGraphs"
                />
            </CCol>     
          <CCol md="6">
            <CustomersDistributionGraph ref="customersDistributionGraph" title="" :month="month"></CustomersDistributionGraph>
          </CCol>      
          <CCol md="6">
            <CustomersDistribution ref="customerDistribution" title="" :month="month"></CustomersDistribution>
          </CCol>                       
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>

import store from '../../store';
import ws from '../../services/dashboards';
import moment from 'moment';
import moment_timezone from 'moment-timezone';

import SellersWithSales from '../../components/graphs/SellersWithSales.vue';
import CustomersReached from '../../components/graphs/CustomersReached.vue';

import MonthSales from '../../components/graphs/MonthSales.vue';
import CedisPerformance from '../../components/graphs/CedisPerformance.vue';
import MonthPerformance from '../../components/graphs/MonthPerformance.vue';

import CustomersDistribution from '../../components/graphs/CustomersDistribution.vue';
import CustomersDistributionGraph from '../../components/graphs/CustomersDistributionGraph.vue';

import DonoutGraph from '../../components/graphs/DonoutGraph.vue';
import GaugePercentGraph from '../../components/graphs/GaugePercentGraph.vue';
import DonoutGraphMini from '../../components/graphs/DonoutGraphMini.vue';
import StackedBarGraph from '../../components/graphs/StackedBarGraph.vue';
import DoubleBarVerticalGraph from '../../components/graphs/DoubleBarVerticalGraph.vue';
import HomeMenu from '../../components/menu/HomeMenu.vue';
import ProjectionTable from '../../components/graphs/ProjectionTable.vue';

export default {
  name: 'Dashboard',
  components: {
    MonthSales,
    CustomersReached,
    SellersWithSales,
    HomeMenu,
    StackedBarGraph,
    CedisPerformance,
    MonthPerformance,
    CustomersDistribution,
    DoubleBarVerticalGraph,
    GaugePercentGraph,
    CustomersDistributionGraph,
    ProjectionTable
  },
  props: {
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data () {
    return {
      items: [],     
      monthOptions: [], 
      month: "202405",
      cedisOptions: [],
      cedis: ""
    }
  },
  beforeMount() {
    this.month = moment().format("YYYYMM");
    this.month_2 = moment().subtract(1, 'year').format("YYYYMM");
  },
  mounted: async function() {
    let role = localStorage.getItem("role");

    if(role != 'administrator'){
      if(role == "supplier"){
        window.location.href = "/#/welcome"; return;
      }
      else{
        if(role == "manager"){
          window.location.href = "/#/welcome"; return;
        }
        else{
          window.location.href = "/#/404"; return;
        } 
      } 
    }

    let res = await ws.getMonths();

    if(res.type == "success"){
        this.monthOptions = res.data;
    }

    let res2 = await ws.getCedis();

    if(res2.type == "success"){
        this.cedisOptions = res2.data;
    }

    //this.loadGraphs();
  },
  methods: {
    showChallenge () {
      router.push({path: '/challenges/'+this.challenge.id+'/view'});
    },
    async reloadGraphs(){
      await this.$refs.sellerWithSales.reloadGraph(this.month);
      await this.$refs.customersReached.reloadGraph(this.month);
      await this.$refs.monthSales.reloadGraph(this.month);
      await this.$refs.cedisPerformance.reloadGraph(this.month);
      await this.$refs.monthPerformance.reloadGraph(this.month);
      await this.$refs.projectionTable.reloadGraph(this.month);

      await this.$refs.customersDistributionGraph.reloadGraph(this.month);
      await this.$refs.customerDistribution.reloadGraph(this.month, this.cedis);
    },
    async reloadCustomerGraphs(){
      await this.$refs.customerDistribution.reloadGraph(this.month, this.cedis);
    },
    setColor (value) {
      let $color
      if (value > 0) {
        $color = 'good';        
      } else {
        $color = 'bad';
      }
      return $color
    }
  }
}
</script>

<style>
  #dates .card-body{
    padding: 0px important!;
  }

  .text-value{
    font-size: 32px;
    text-align: right;
  }

  .text-muted{
    font-size: 18px;
    text-align: right;
  }

  .widget{
    .card-body{
      div:nth-child(2) {
        width: 100%;
      }
    }
  }

  .form-group{
    margin: 0px;
  }

  #total_balance{
    .card-body{
      div:nth-child(2) {
        width: 100%;
      }
    }
  }
  .good{
    background-color: #b0d136;
    color: black;
  }

  .bad{
    background-color: #f04922;
    color: white;
  }

  .metric{
    .card-body{
      padding: 0px 14px;
    }
  }
</style>