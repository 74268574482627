<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="3">
                <CInput
                  label="Código:"
                  :lazy="false"
                  :value.sync="$v.form.code.$model"
                  :isValid="checkIfValid('code')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol md="9">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Correo Electrónico (Usuario):"
                  :lazy="false"
                  :value.sync="$v.form.email.$model"
                  :isValid="checkIfValid('email')"
                  placeholder=""
                  autocomplete="given-email"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
                <CCol md="6">
                    <CInput
                      :isValid="checkIfValid('password')"
                      :value.sync="$v.form.password.$model"
                      label="Contraseña"
                      type="password"
                      placeholder=""
                      autocomplete="new-password"
                      invalidFeedback="Debe tener al menos 8 caracteres y teniendo al menos un número, una mayúscula y una minúscula."
                  />
                </CCol>
                <CCol md="6">
                  <CInput
                    :isValid="checkIfValid('confirmPassword')"
                    :value.sync="$v.form.confirmPassword.$model"
                    label="Confirmar Contraseña"
                    type="password"
                    placeholder=""
                    autocomplete="new-password"
                    invalidFeedback="Las contraseñas no coinciden."
                />
                </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../services/managers';
import store from '../../store'

export default {
  name: 'ManagerModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Supervisor",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      link: '',
      item: false,
      managers: [],
      managersOptions: []
    }
  },
  mounted: async function() {
    
  },
  methods: {
    storeModal () {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nuevo Supervisor';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          code: '',
          name: '',
          email: '',
          manager_id: '',
          password: '',
          confirmPassword: ''
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Supervisor';
      this.isEdit = true;
      this.submitted = false;

      this.item = item;

      if(item.user.api_token){
        this.link = "https://app.kpibonus.com/#/"+item.user.api_token+"/login-link";
      }
      else{
        this.link = false;
      }

      this.form = {
          id: item.id,
          code: item.code,
          name: item.name,
          email: item.user.email ? item.user.email : '',
          manager_id: item.manager_id,
          password: '********',
          confirmPassword: '********'
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    async generate () {
      let params = {
          id: this.form.id,
          code: this.form.code,
          name: this.form.name
      };

      let response = await ws.generateLink(this.form);
      
      if(response.type == "success"){
        this.link = response.data.link;
        this.form.email = response.data.user.email;
        this.form.password = '12345678';

        this.$emit("update", this.form );
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          code: '',
          name: '',
          email: '',
          manager_id: '',
          password: '**********',
          confirmPassword: '**********'
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    redirectLink(link){
      window.location = link;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      code: {
        required
      },
      email: {
        required,
        minLength: minLength(3)
      },
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
}
</script>