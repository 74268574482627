<template>
    <div>
        <div class="text-center">
            <div style="font-size: 16px;"><strong>Desempeño por CEDIS</strong></div>
        </div>        
        <div style="width: 100%; height: 700px;" ref="cedisPerformanceGraph">                            
        </div>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5index from "@amcharts/amcharts5/index";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import ws from '@/services/graphs';

import ws from '../../services/dashboards';
import store from '../../store'

export default {
    name: 'CedisPerformance',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default() {
                return "Desempeño por CEDIS"
            }
        },
        month: {
            type: String,
            default: '202401'
        }              
    },
    components : {
	},
    data: function () {
		return {
            data: {},
            items: {},
            root: "",
            chart: "",
            xAxis:"",
            yAxis:"",
            wheelX: false,
            wheelY: false,
            data_response:"",
            legend:"",
        }
    },
    computed: {
        
    },
    mounted: async function(){        
        this.loading();

        let response = await ws.getCedisPerformance(this.month);

        if(response.type == "success"){
            this.data = response.data;

            this.loadGraph(this.data);
        }

        this.loaded();
    },
    methods: {
        makeSeries(field, name, root, data_response) {
            let series = this.chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: this.xAxis,
                yAxis: this.yAxis,
                wheelX: false,
                wheelY: false,
                valueXField: field,
                categoryYField: "cedis",
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
                })
            }));

            series.columns.template.setAll({
                height: am5.p100,
                strokeOpacity: 0
            });


            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                locationX: 1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    centerY: am5.p50,
                    text: "{valueX}",
                    populateText: true
                })
                });
            });

            // series.bullets.push(function () {
            //     return am5.Bullet.new(root, {
            //     locationX: 1,
            //     locationY: 0.5,
            //     sprite: am5.Label.new(root, {
            //         centerX: am5.p100,
            //         centerY: am5.p50,
            //         text: "{name}",
            //         fill: am5.color(0xffffff),
            //         populateText: true
            //     })
            //     });
            // });

            series.data.setAll(data_response);
            series.appear();

            return series;
        },
        async loadGraph(data){
            if (this.root) {
                this.root.dispose();
            }

            this.root = am5.Root.new(this.$refs.cedisPerformanceGraph);

            this.root.setThemes([
                am5themes_Animated.new(this.root)
            ]);
            
            this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
                panX: false,
                panY: false,
                wheelX: false,
                wheelY: false,
                paddingLeft:0,
                layout: this.root.verticalLayout
            }));

            this.legend = this.chart.children.push(am5.Legend.new(this.root, {
                centerX: am5.p50,
                x: am5.p50
            }))

            this.yAxis = this.chart.yAxes.push(am5xy.CategoryAxis.new(this.root, {
                categoryField: "cedis",
                renderer: am5xy.AxisRendererY.new(this.root, {
                    inversed: true,
                    cellStartLocation: 0.1,
                    cellEndLocation: 0.9,
                    minorGridEnabled: true
                })
            }));

            this.yAxis.data.setAll(data.data);

            this.xAxis = this.chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
                renderer: am5xy.AxisRendererX.new(this.root, {
                    strokeOpacity: 0.1,
                    minGridDistance: 50, 
                }),
                min: 0
            }));

            //Quitar labels del eje de las Xs
            this.xAxis.get("renderer").labels.template.set("forceHidden", true);

            this.makeSeries("last", data.label_2,this.root, data.data);
            this.makeSeries("current", data.label_1,this.root, data.data);

            this.legend = this.chart.children.push(am5.Legend.new(this.root, {
                centerX: am5.p50,
                x: am5.p50
            }));

            this.legend.data.setAll(this.chart.series.values);

            let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
                behavior: "zoomY"
            }));
            cursor.lineY.set("forceHidden", true);
            cursor.lineX.set("forceHidden", true);

            this.chart.appear(1000, 100);
         
        },
        async reloadGraph(month){
            this.loading();

            let response = await ws.getCedisPerformance(month);

            if(response.type == "success"){
                this.data = response.data;

                this.loadGraph(this.data);
            }

            this.loaded();
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
  }
}

</script>
