<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()">Nuevo Vendedor</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :tableFilter="tableFilter"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #code="{item}"><td class="text-center w100">{{item.code}}</td></template>
        <template #manager_id="{item}"><td class="text-center w300">{{item.manager_id > 0 ? item.manager.name : '-'}}</td></template>
        <template #user="{item}">
        <td class="text-center w200">
          {{item.user && item.user.api_token ? item.user.email : '-'}}
          <span v-if="item.user && item.user.api_token"><br />{{item.user.last_login | date}}<br /><br /><CButton color="success" size="sm" @click="login(item)"><CIcon name="cil-https"/></CButton></span>
          </td>
        </template>
        <template #actions="{item}">
          <td class="table_actions b6">
            <CButton color="info" size="sm" @click="showSales(item)"><CIcon name="cil-zoom"/> Ventas</CButton>
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <SupplierModal ref="supplierModal" @store="storeProcess" @update="updateProcess"></SupplierModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import SupplierModal from './SupplierModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/suppliers';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'SuppliersTable',
  components: { SupplierModal, DeleteModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'code', label: 'Código'},
          {key: 'name', label: 'Nombre'},
          {key: 'manager_id', label: 'Supervisor'},
          {key: 'user', label: 'Acceso'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      supplier_id: 0,
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      }
    }
  },
  methods: {
    showSales (item) {
      router.push({path: '/suppliers/'+item.id+'/'+(this.slugify(item.name))+'/sales'});
    },
    storeModal () {
      this.$refs.supplierModal.storeModal();
    },
    updateModal (item) {
      this.$refs.supplierModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Vendedor", item.id, item.name);
    },
    login (item) {
      window.location = "https://app.kpibonus.com/#/"+item.user.api_token+"/login-link";
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
