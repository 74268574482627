<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <div class="card-header-actions" style="text-align: right; padding-top: 2px;">
            <CButton color="info" size="sm" @click="showChallenge()" style="margin-right: 5px;">Ver Concurso</CButton>
          </div>
          <h3 style="margin-bottom: 0px;">{{challenge.name}}</h3>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="12">
              <CRow>                           
                <CCol md="12">
                  <CWidgetIcon class="roi" style="width: 160px; height: 120px; float: right; font-size:10px !important;"
                    :header="data.roi"
                    text="ROI"
                    color="success"
                  >
                    <CIcon name="cil-balance-scale" width="24"/>                                    
                  </CWidgetIcon>               
                  <CWidgetSimple id="dates" style="width: 400px; height: 120px; float: right; margin-right: 10px;">
                    <div class="m-0" style="font-size: 32px; font-weight: bold; padding: 0px;">{{data.dates}}</div>
                    <div style="margin-top: 12px; font-weight: bold;">Dias transcurridos: {{data.days}}</div>          
                  </CWidgetSimple>      
                  <CRow>
                    <CCol>
                      {{(challenge.type == 'sales' ? 'Venta Mínima' : (challenge.type == 'customers' ? 'Clientes Mínimos' : challenge.type == 'boxes' ? 'Cajas Mínimo' : ''))}}
                      <span v-if="challenge.type == 'boxes'"><br />{{(challenge.mechanic == 'total' ? 'Numero Total de Cajas Vendidas' : challenge.mechanic == 'ranges' ? 'Rangos de Cajas Vendidas' : 'Individual Por Cada Caja Vendida')}}</span>
                      <br /><br />
                      {{challenge.description}}
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>                           
                <CCol md="3">
                  <CWidgetIcon
                    id="total_sale"
                    :header="'$ '+data.total_sales"
                    text="Venta Total"
                    color="gradient-primary"
                  >
                    <CIcon name="cilMoney" width="24"/>
                  </CWidgetIcon>           
                    <CCard style="height: 320px;">
                      <SellersWithSales ref="sellerWithSales" title="Vendedores con Ventas" :challenge="challenge"></SellersWithSales>
                    </CCard>
                </CCol>
                <CCol md="3">
                  <CWidgetIcon
                    id="total_balance"
                    :header="'$ '+data.total_points"
                    text="Inversión Total"
                    color="gradient-primary"
                  >
                    <CIcon name="cilMoney" width="24"/>
                  </CWidgetIcon>                          
                  <CCard style="height: 320px;">
                    <CustomersReached ref="customersReached" title="Clientes Alcanzados:" :challenge="challenge"></CustomersReached>
                  </CCard>
                </CCol>     
                <CCol md="6">
                  <CCard>
                    <CedisPerformance ref="cedisPerformance" title="Desempeño por CEDIS" :challenge="challenge"></CedisPerformance>
                  </CCard>
                </CCol>
              </CRow>              
          </CCol>                   
        </CRow>
        <CRow>          
          <CCol md="6">
            <StackedBarGraph ref="stackedBarGraph1" title="Desempeño por Supervisor"></StackedBarGraph>
          </CCol>      
          <CCol md="6">
            <StackedBarGraph ref="stackedBarGraph2" title="Desempeño por Ruta"></StackedBarGraph>
          </CCol>                       
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>

import store from '../../store';
import challenges_ws from '../../services/challenges';
import ws from '../../services/dashboards';
import router from '../../router/index'

import SellersWithSales from '../../components/challenges/dashboard/SellersWithSales.vue';
import CustomersReached from '../../components/challenges/dashboard/CustomersReached.vue';
import CedisPerformance from '../../components/challenges/dashboard/CedisPerformance.vue'
import StackedBarGraph from '../../components/challenges/dashboard/StackedBarGraph.vue';

import SalesVsTarget from '../../components/graphs/SalesVsTarget.vue';
import DoubleBarVerticalGraph from '../../components/graphs/DoubleBarVerticalGraph.vue';
import HomeMenu from '../../components/menu/HomeMenu.vue';

export default {
  name: 'ChallengeDashboard',
  components: {
    SellersWithSales,
    CustomersReached,
    CedisPerformance,
    HomeMenu,
    StackedBarGraph,
    SalesVsTarget,
    DoubleBarVerticalGraph
  },
  data () {
    return {
      challenge_id: 0,
      challenge: {},
      data: {}
    }
  },
  async beforeMount() {
    let challenge_id = await this.$route.params.challenge_id;
    this.challenge_id = challenge_id;

    let response = await challenges_ws.show(this.challenge_id); 

    if(response.type == "success"){
      this.challenge = response.data;
    }
  },
  mounted: async function() {
    let role = localStorage.getItem("role");

    if(role != 'administrator'){
      if(role == "supplier"){
        window.location.href = "/#/welcome"; return;
      }
      else{
        if(role == "manager"){
          window.location.href = "/#/welcome"; return;
        }
        else{
          window.location.href = "/#/404"; return;
        } 
      } 
    }

    let challenge_id = await this.$route.params.challenge_id;
    this.challenge_id = challenge_id;

    let response2 = await challenges_ws.show(this.challenge_id); 

    if(response2.type == "success"){
      this.challenge = response2.data;
    }

    let response = await ws.getChallengeData(this.challenge_id); 

    if(response.type == "success"){
      this.data = response.data;

      this.loadGraphs();
    }
  },
  methods: {
    async loadGraphs(){
      let _this = this;
        setTimeout(async function(){
          await _this.$refs.sellerWithSales.reloadGraph(_this.challenge);
          await _this.$refs.customersReached.reloadGraph(_this.challenge);
          await _this.$refs.cedisPerformance.reloadGraph(_this.challenge);

          await _this.$refs.stackedBarGraph1.reloadGraph(_this.challenge, "manager");     
          await _this.$refs.stackedBarGraph2.reloadGraph(_this.challenge, "supplier");     
          //  await this.$refs.doubleBarHorizontalGraph.loadGraph("");
          //  await this.$refs.doubleBarVerticalGraph.loadGraph("");
        },500);
    },
    showChallenge () {
      router.push({path: '/challenges/'+this.challenge.id+'/view'});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
</script>
<style>
  #dates .card-body{
    padding: 0px important!;
  }

  .text-value{
    font-size: 32px;
    text-align: right;
  }

  .text-muted{
    font-size: 18px;
    text-align: right;
  }

  #total_sale{
    .card-body{
      div:nth-child(2) {
        width: 100%;
      }
    }
  }

  #total_balance{
    .card-body{
      div:nth-child(2) {
        width: 100%;
      }
    }
  }

  .roi{
    .text-value{
      font-size: 24px;
      text-align: right;
    }
  }
</style>
