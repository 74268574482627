<template>
    <div>
        <br /><br />
        <CRow>          
            <CCol md="6" class="text-center">
                <div>
                    <div class="text-center">
                        <div style="font-size: 16px;"><strong>Distribución de Clientes<br />{{data.graph_2.label_1}}</strong><br /><br /><span style="font-size: 14px;">{{data.graph_2.reached_customers}} de {{data.graph_2.total_customers}}</span></div>
                    </div>
                </div>
                <div style="height: 200px;" ref="donoutChart2">                            
                </div>              
            </CCol>                       
            <CCol md="6" class="text-center">
                <div>
                    <div class="text-center">
                        <div style="font-size: 16px;"><strong>Distribución de Clientes<br />{{data.graph_1.label_1}}</strong><br /><br /><span style="font-size: 14px;">{{data.graph_1.reached_customers}} de {{data.graph_1.total_customers}}</span></div>
                    </div>
                </div>
                <div style="height: 200px;" ref="donoutChart1">                            
                </div>          
            </CCol>                       
        </CRow>     
        <br>
        <br> 
        <CRow>                           
            <CCol md="6">
            <CWidgetSimple style="padding: 0px;" class="metric">
                <CRow>  
                    <CCol md="12" style="font-size: 30px;"> 
                    {{data.graph_2.reached_customers}}
                    </CCol> 
                </CRow>
                <CRow>  
                    <CCol md="12" style="background-color: #3465a4; color: #fff; width: 100%; margin: 0px; padding: 0px;"> 
                    {{data.graph_2.label_1}}
                    </CCol> 
                </CRow>
                </CWidgetSimple>
            </CCol>
            <CCol md="6">
            <CWidgetSimple style="padding: 0px;" class="metric">
                <CRow>  
                    <CCol md="12" style="font-size: 30px;"> 
                    {{data.graph_1.reached_customers}}
                    </CCol> 
                </CRow>
                <CRow>  
                    <CCol md="12" style="background-color: #3465a4; color: #fff; width: 100%; margin: 0px; padding: 0px;"> 
                    {{data.graph_1.label_1}}
                    </CCol> 
                </CRow>
                </CWidgetSimple>
            </CCol>
            <CCol md="12">
            <CWidgetSimple style="padding: 0px;" class="metric">
                <CRow>  
                    <CCol md="12" style="font-size: 30px;"> 
                    {{data.graph_1.total_customers}}
                    </CCol> 
                </CRow>
                <CRow>  
                    <CCol md="12" style="background-color: #3465a4; color: #fff; width: 100%; margin: 0px; padding: 0px;"> 
                    Total de Clientes
                    </CCol> 
                </CRow>
                </CWidgetSimple>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import ws from '@/services/graphs';

import ws from '../../services/dashboards';
import store from '../../store'

export default {
    name: 'CustomersDistribution',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default: ''
        },
        month: {
            type: String,
            default: '202401'
        },
        cedis: {
            type: String,
            default: ''
        }
    },
    components : {
	},
    data: function () {
		return {
            data: {
                graph_1: {},
                graph_2: {}
            },
            root1: "",
            root2: "",
        }
    },
    computed: {
        
    },
    mounted: async function(){        
        this.loading();

        let response = await ws.getCustomersDistribution(this.month, this.cedis);

        if(response.type == "success"){
            this.data = response.data;

            this.loadGraph1(this.data.graph_1);
            this.loadGraph2(this.data.graph_2);
        }

        this.loaded();
    },
    methods: {
        async loadGraph1(data){
            let data_response = [
                {
                    "code": "success",
                    "service" : "Alcanzados",
                    "total" : data.reached_customers
                },
                {
                    "code": "dark",
                    "service" : "Pendientes",
                    "total" : data.total_customers - data.reached_customers
                }
            ];

            if (this.root1) {
                this.root1.dispose();
            }   
            
            this.root1 = am5.Root.new(this.$refs.donoutChart1);
            
            this.root1.setThemes([am5themes_Animated.new(this.root1)]);

            // Se inicializa el contenedor (al parecer puede contener varias graficas este mismo contenedor NO PROBADO)
            let chart = this.root1.container.children.push(
                // Se inicializa el tipo de grafica
                am5percent.PieChart.new(this.root1, {
                    layout: this.root1.verticalLayout,
                    innerRadius: am5.percent(50)
                })
            );


            // Creamos la serie (cada serie seria cada columna)
            let serie1 = chart.series.push(am5percent.PieSeries.new(this.root1, {
                    name: "Series1",
                    valueField: "total",
                    categoryField: "service",
                    legendLabelText: "",
                }));        

            // serie1.labels.template.set("forceHidden", true);
            // serie1.ticks.template.set("forceHidden", true);

            serie1.labels.template.disabled = true;
            serie1.ticks.template.disabled = true;

            // Disable tooltips
            serie1.slices.template.set("tooltipText", "");

            serie1.ticks.template.set("visible", false);
            serie1.labels.template.set("visible", false);

            //Si se tiene que repetir
            serie1.data.setAll(data_response);
            //relleno
            serie1.slices.template.adapters.add("fill", function(fill, target) {
                let context = target.dataItem.dataContext;
                switch (context.code) {
                    case "success":
                        return am5.color(0x1B9E3E);
                    case "warning":
                        return am5.color(0xF9B115)
                    case "poor":
                        return am5.color(0xE55353)
                    case "dark":
                        return am5.color(0x6b7785)                                           
                    default:
                        return am5.color(0x6b7785)
                }                                   
            });
            //Orillas
            serie1.slices.template.adapters.add("stroke", function(stroke, target) {
                let context = target.dataItem.dataContext;
                switch (context.code) {
                    case "success":
                        return am5.color(0x1B9E3E);
                    case "warning":
                        return am5.color(0xF9B115)
                    case "poor":
                        return am5.color(0xE55353)
                    case "dark":
                        return am5.color(0x6b7785)                                           
                    default:
                        return am5.color(0x6b7785)
                }                                   
            });

            serie1.data.setAll(data_response);

            let legend = chart.children.push( 
                am5.Legend.new(this.root1, {
                    y: am5.percent(85),
                    centerX: am5.percent(50),
                    x: am5.percent(50),
                })
            );

            
            legend.data.setAll([serie1.dataItems[0]]);
            
            serie1.appear(1000, 100);
        },
        async loadGraph2(data){
            let data_response = [
                {
                    "code": "success",
                    "service" : "Alcanzados",
                    "total" : data.reached_customers
                },
                {
                    "code": "dark",
                    "service" : "Pendientes",
                    "total" : data.total_customers - data.reached_customers
                }
            ];

            if (this.root2) {
                this.root2.dispose();
            }   
            
            this.root2 = am5.Root.new(this.$refs.donoutChart2);
            
            this.root2.setThemes([am5themes_Animated.new(this.root2)]);

            // Se inicializa el contenedor (al parecer puede contener varias graficas este mismo contenedor NO PROBADO)
            let chart = this.root2.container.children.push(
                // Se inicializa el tipo de grafica
                am5percent.PieChart.new(this.root2, {
                    layout: this.root2.verticalLayout,
                    innerRadius: am5.percent(50)
                })
            );


            // Creamos la serie (cada serie seria cada columna)
            let serie1 = chart.series.push(am5percent.PieSeries.new(this.root2, {
                    name: "Series1",
                    valueField: "total",
                    categoryField: "service",
                    legendLabelText: "",
                }));        

            // serie1.labels.template.set("forceHidden", true);
            // serie1.ticks.template.set("forceHidden", true);

            serie1.labels.template.disabled = true;
            serie1.ticks.template.disabled = true;

            // Disable tooltips
            serie1.slices.template.set("tooltipText", "");

            serie1.ticks.template.set("visible", false);
            serie1.labels.template.set("visible", false);

            //Si se tiene que repetir
            serie1.data.setAll(data_response);
            //relleno
            serie1.slices.template.adapters.add("fill", function(fill, target) {
                let context = target.dataItem.dataContext;
                switch (context.code) {
                    case "success":
                        return am5.color(0x1B9E3E);
                    case "warning":
                        return am5.color(0xF9B115)
                    case "poor":
                        return am5.color(0xE55353)
                    case "dark":
                        return am5.color(0x6b7785)                                           
                    default:
                        return am5.color(0x6b7785)
                }                                   
            });
            //Orillas
            serie1.slices.template.adapters.add("stroke", function(stroke, target) {
                let context = target.dataItem.dataContext;
                switch (context.code) {
                    case "success":
                        return am5.color(0x1B9E3E);
                    case "warning":
                        return am5.color(0xF9B115)
                    case "poor":
                        return am5.color(0xE55353)
                    case "dark":
                        return am5.color(0x6b7785)                                           
                    default:
                        return am5.color(0x6b7785)
                }                                   
            });

            serie1.data.setAll(data_response);

            let legend = chart.children.push( 
                am5.Legend.new(this.root2, {
                    y: am5.percent(85),
                    centerX: am5.percent(50),
                    x: am5.percent(50),
                })
            );

            
            legend.data.setAll([serie1.dataItems[0]]);
            
            serie1.appear(1000, 100);
        },
        async reloadGraph(month, cedis){
            this.loading();

            let response = await ws.getCustomersDistribution(month, cedis);

            if(response.type == "success"){
                this.data = response.data;

                this.loadGraph1(this.data.graph_1);
                this.loadGraph2(this.data.graph_2);
            }

            this.loaded();
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
  	beforeDestroy() {
        if (this.root1) {
            this.root1.dispose();
        }

        if (this.root2) {
            this.root2.dispose();
        }
    }
}

</script>
