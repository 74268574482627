<template>
    <div>
        <CWidgetIcon
            class="widget"
            :header="'$ '+available_investment"
            :text="available_investment_label"
            color="gradient-primary"
            style="height: 117px;"
        >
            <CIcon name="cilMoney" width="24"/>
        </CWidgetIcon>
        <CWidgetIcon
            class="widget"
            :header="'$ '+month_sales"
            :text="month_sales_label"
            color="gradient-primary"
            style="height: 140px;"
        >
        <template #footer>
          <div v-if="month_sales.replace(/,/g, '') - available_investment.replace(/,/g, '') > 0" style="float: right; width: 100%; text-align: right; padding: 10px; font-weight:bold; color: #090; font-size: 20px;">{{Math.round((month_sales.replace(/,/g, "") - available_investment.replace(/,/g, "")) / available_investment.replace(/,/g, "") * 100)}}%</div>
          <div v-if="month_sales.replace(/,/g, '') - available_investment.replace(/,/g, '') < 0" style="float: right; width: 100%; text-align: right; padding: 10px; font-weight:bold; color: #F00; font-size: 20px;">{{Math.round((month_sales.replace(/,/g, "") - available_investment.replace(/,/g, "")) / available_investment.replace(/,/g, "") * 100)}}%</div>
        </template>
            <CIcon name="cilChartLine" width="24"/>
        </CWidgetIcon>    
    </div>
</template>

<script>
// import ws from '@/services/graphs';

import ws from '../../services/dashboards';
import store from '../../store'

export default {
    name: 'MonthSales',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default: ''
        },
        month: {
            type: String,
            default: '202401'
        }
    },
    components : {
	},
    data: function () {
		return {
            month_sales: "0.00",
            month_sales_label: "Venta del Mes",
            available_investment: "0.00",
            available_investment_label: "Venta del Mes"
        }
    },
    computed: {
        
    },
    mounted: async function(){        
        this.loading();

        let response = await ws.getMonthSales(this.month);

        if(response.type == "success"){
            this.month_sales = response.data.amount;
            this.month_sales_label = response.data.label;
        }

        let response2 = await ws.getAvailableInvestment(this.month);

        if(response2.type == "success"){
            this.available_investment = response2.data.amount;
            this.available_investment_label = response2.data.label;
        }

        this.loaded();
    },
    methods: {
        async reloadGraph(month){
            this.loading();

            let response = await ws.getMonthSales(month);

            if(response.type == "success"){
                this.month_sales = response.data.amount;
                this.month_sales_label = response.data.label;
            }

            let response2 = await ws.getAvailableInvestment(month);

            if(response2.type == "success"){
                this.available_investment = response2.data.amount;
                this.available_investment_label = response2.data.label;
            }

            this.loaded();
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    }
}

</script>
